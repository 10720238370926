import React, { Component } from "react";

import "font-awesome/css/font-awesome.css";
import "./all.sass";
import {
  Container,
  Hero,
  HeroBody,
  Title,
  Columns,
  Column,
  Content
} from "bloomer";
import Helmet from "react-helmet";
import Particles from "react-particles-js";
import {graphql} from 'gatsby'
import Img from "gatsby-image"

class Onepager extends Component {

    constructor({data}) {
        super();
        this.data = data;
        console.log(data);
    }

  render() {
    return (
      <div>
        <Helmet>
          <title>Durable Medium</title>
          <meta
            name="description"
            content="Blockchain based document timestamping"
          />
        </Helmet>
        <Container isFluid isFullWidth isMarginless>
          <Particles
            params={{
              particles: {                    
                    "shape":{
                        "type":"circle",
                        "stroke":{
                        "width":0,"color":"#8d772a"
                    },
                    "polygon": {
                        "nb_sides":5
                    }
                },
                size: {
                    "value":4,"random":true,"anim": {
                        "enable":false,"speed":40,"size_min":0.1,"sync":false
                    }
                },
                color: { value: "#8d772a" },
                line_linked: { color: "#8d772a" }
              }
            }}
          />
          <Hero isColor="primary">
            <HeroBody>
              <Container>
                <Title isSize="1">
                  Durable
                  <br />
                  Medium
                </Title>
                <h2>Blockchain based document timestamping</h2>
              </Container>
            </HeroBody>
          </Hero>
        </Container>

        <Container isFluid isMarginless>
          <Hero isColor="primary">
            <HeroBody>
              <Container>
                <Columns>
                  <Column>
                    <Content>
                      <h3>
                        <strong>Regulators require</strong> durable medium
                      </h3>
                      <p>
                        Available solutions include:
                        <br />
                        <b>WORM</b> or <b>physical media</b>
                      </p>
                      <p>
                        Go beyond and use <b>global and open Blockchains</b> to
                        time stamp and store documents like terms of service and
                        offers
                      </p>
                      <p>Easily hash, store and publish public documents</p>
                      <p>
                        We leverage <b>IPFS</b> and <b>OpenTimestamps</b> to
                        offer financial institutions <b>alternative to legacy expensive</b> solutions
                      </p>
                    </Content>
                  </Column>
                </Columns>
              </Container>
            </HeroBody>
          </Hero>
        </Container>

        <Container isFluid  isMarginless>
          <Hero isColor="primary" >
            <HeroBody>
              <Container>
                <Columns>
                  <Column>
                    <Content>
                        <h2>GENERAL INFO</h2>
                        <h4>Let Us Explain The Problem</h4>
                        <p>
                          Various EU regulatory provisions require that a firm
                          must provide certain information to a client in
                          writing, either on <b>paper or in another durable medium</b>. Some
                          provisions also permit the use of digital media but
                          meet certain conditions.
                        </p>
                        <p>
                          A durable medium needs to:
                          </p>
                          <ul>
                            <li>
                              allow information to be addressed personally to
                              the recipient
                            </li>
                            <li>
                              enable the recipient to store information in a way
                              that is <b>accessible for future reference</b>
                            </li>
                            <li>
                              allow the <b>unchanged reproduction</b> of the
                              information stored
                            </li>
                          </ul>
                        <p>
                          Blockchain use is accepted among possible solutions.
                          We propose the use of undisputed global decentralized
                          open access guaranteeing unchanged reproduction and
                          storability for <b>future reference</b> using well
                          known combination of protocols including <b>IPFS</b>, <b>OpenTimestamps</b> and <b>Ethereum</b>
                        </p>
                        <p>
                          All documents including terms of service, price lists
                          digitally signed by the financial institution will be
                          timestamped on the blockchain and stored in permanent
                          web of IPFS
                        </p>
                    </Content>
                  </Column>
                  <Column isSize="1/3" />
                </Columns>
              </Container>
            </HeroBody>
          </Hero>
        </Container>

        <Container isFluid isMarginless>
          <Hero isColor="primary">
            <HeroBody>
              <Container>
                <Columns>
                  <Column>
                    <Content>
                      <h2>How It Works</h2>
                      <p>
                        All documents are stored in so called permanent web
                        powered by IPFS protocol.
                      </p>
                      <p>
                        IPFS protocol took advantage of <b>blockchain protocol</b> and network infrastructure in
                        order to store unalterable data, remove duplicated files
                        across the network, and obtain address information for
                        accessing storage nodes to search for files in the
                        network.
                      </p>
                      <p>
                        IPFS has <b>no single point of failure</b>, and 
                        <b>nodes do not need to trust each other</b> not to
                        tamper with data in transit IPFS provides deduplication,
                        high performance, and clustered persistence. IPFS and
                        the Blockchain are a perfect match!
                      </p>
                      <p>
                        <b>Large amounts of data</b> can be stored with IPFS and
                        linked into a blockchain transaction. This <b>timestamps and secures content</b>, without having to
                        put the data on the chain itself
                      </p>
                    </Content>
                  </Column>
                </Columns>
              </Container>
            </HeroBody>
          </Hero>
        </Container>


        <Container isFluid isMarginless>
          <Hero isColor="primary">
            <HeroBody>
              <Container>
                <Columns>
                  
                  <Column>
                    <Content>
                      <h2>CONTACT INFO</h2>
                      <h2>Mailing Address</h2>
                      <p>
                          INPAY S.A.<br/>
                        Grzybowska 4/106, 00-131 Warsaw, Poland
                      </p>
                      <h2>Email address</h2>
                      <p>
                        info@inpay.pl
                      </p>
                      
                    </Content>
                  </Column>
                  <Column isSize="1/3">
                  <Img fluid={this.data.file.childImageSharp.fluid}/>
                  </Column>
                </Columns>
              </Container>
            </HeroBody>
          </Hero>
        </Container>
      </div>
    );
  }
}

export default Onepager;

export const query = graphql`
  query {
    file(relativePath: { eq: "enter-blockchain.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
  `
